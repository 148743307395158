// @material-ui/icons
import HighlightOff from "@material-ui/icons/HighlightOff";
import Person from "@material-ui/icons/Person";
import React from 'react';
import { Redirect } from 'react-router';
// core components/views
import { setLocalStorage, token } from "utilities/helper";
import CustomerPage from "views/Customer/CustomerPage";

function logout() {
  setLocalStorage(token, null);
  return <Redirect to='/dashboard' />;
}

const dashboardRoutesReception2 = [
  {
    path: "/customers",
    sidebarName: "Associati",
    navbarName: "Associati",
    icon: Person,
    component: CustomerPage
  },
  {
    path: "/logout",
    sidebarName: "Esci",
    navbarName: "Logout",
    icon: HighlightOff,
    component: logout
  },
  { redirect: true, path: "/", to: "/customers", navbarName: "Redirect" }
];

export default dashboardRoutesReception2;
