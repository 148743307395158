import Grid from "@material-ui/core/Grid";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { dashboard, getObjects, postObjects } from 'api/api.jsx';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from '@material-ui/core/Button';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { filterByField } from "utilities/helper";


class DashboardBar extends React.Component {
  state = {
    value: 0,
    dataDashboard: null,
    refresh: true,
    page: 0,
    rowsPerPage: 50,
    openBar: false,
    numberCard: "",
    key: '',
    menuIsOpen: false,
    customerName: ""
  };

  callbackErrorGetObjects = (error) => {
    this.setState({
      refresh: false,
      openBar: false,
      customers_live: undefined,
      key: ''
    });
  };

  sendOrder = () => {
    if (this.state.key) {
      postObjects({ products: this.state.products.filter(function (product) { return product.quantity > 0 }) }, "products/sendorder/key/" + this.state.key,
        () => this.setState({
          key: '',
          selectedOptionCustomer: "",
          products: undefined,
          numberCard: '',
          refresh: true
        })
        ,
        (error) => { alert(error) });
    } else {
      this.setState({
        refresh: true
      });
    }

  };

  callbackDashboard = (dashboard) => {
    var customers_live = dashboard.data.customers_live.map((prop, key) => {
      return { value: prop.key, label: prop.key + " - " + prop.firstname };
    });
    this.setState({
      dataDashboard: dashboard.data,
      customers_live: customers_live,
      refresh: false,
      openBar: false,
      key: ''
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page: page
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onArticleClick = (cell, row, event) => {
    var filtered = filterByField(this.state.dataDashboard.customers_live, "id", row.rowData[0])[0];
    this.setState({
      openBar: true,
      object: filtered
    });
  }

  handleCloseBar = () => {
    this.setState({ openConfirm: false, refresh: true });
  };

  handleOpen = () => {
    this.setState({ openBar: true, object: undefined });
  }

  checkKey(key) {
    return;
  }

  textInputChanged = (event) => {
    const key = event.target.value;
    var customer = this.state.dataDashboard.customers_live.filter(function (customer) {
      return customer.key === key;
    });
    if (customer.length === 1) {
      this.setState({
        key: key,
        refreshArticle: true,
        selectedOptionCustomer: [{ value: customer.value, label: customer.label }],
        customerName: customer[0].firstname
      });
    }
    else {
      this.setState({
        key: key,
        products: undefined,
        customerName: ""
      });
    }
  }

  addProduct = (id) => {
    //getObjects("products/" + id + "/key/" + this.state.key + "/add", this.callback, this.callbackError);
    var products = this.state.products;
    products.map(product => product.id === id ? product.quantity = product.quantity + 1 : null)
    this.setState(
      products
    )
  }

  removeProduct = (id) => {
    var products = this.state.products;
    products.map(product => product.id === id ? product.quantity = product.quantity - 1 : null)
    this.setState(
      products
    )
    //getObjects("products/" + id + "/key/" + this.state.key + "/remove", this.callback, this.callbackError);
  }

  handleChangeCustomer = (selectedOptionCustomer) => {
    this.setState({
      selectedOptionCustomer: [{ value: selectedOptionCustomer.value, label: selectedOptionCustomer.label }],
      refreshArticle: true,
      key: selectedOptionCustomer.value
    });
  }

  callback = (response) => {
    this.setState({ products: response.data.data, refreshArticle: false })
  }

  callbackError = (error) => {
    this.setState({ products: [], refreshArticle: false });
  }

  getArticle = () => {
    if (this.state.key)
      getObjects("products/key/" + this.state.key, this.callback, this.callbackError);
    else
      getObjects("products/key/" + 0, this.callback, this.callbackError);
  }

  menuOpened = (dashboard) => {
    var customers_live = dashboard.data.customers_live.map((prop, key) => {
      return { value: prop.key, label: prop.key + " - " + prop.firstname };
    });
    this.setState({
      dataDashboard: dashboard.data,
      customers_live: customers_live,
      refresh: false,
      openBar: false,
      key: undefined,
      menuIsOpen: true
    });
  }


  render() {
    if (this.state.refresh) {
      dashboard(this.callbackDashboard);
    }
    if (this.state.refreshArticle) {
      this.getArticle();
    }
    var placeholder = (<div>Caricamento dashboard</div>);

    if (this.state.dataDashboard !== null) {

      placeholder = (
        <div>
          <Grid container>
            <GridItem md={1}></GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader color="primary">
                  <p className={this.props.classes.cardTitleWhite}>Servizio bar NEXUS</p>
                </CardHeader>
                <CardBody style={{}}>

                  <div>Numero chiave</div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: '1' }}>
                      <input type='tel'
                        style={{
                          width: '82px',
                          fontSize: '24px',
                          fontWeight: 'bold',
                          height: '100%',
                          backgroundColor: (this.state.products !== undefined && this.state.products.length > 0) ? "#66bb6a" : "#e8f5e9"
                        }}
                        value={this.state.key}
                        onChange={(event) => this.textInputChanged(event)}
                      />
                      {/*<CustomInput
                        labelProps={{
                          type: 'tel'

                        }}
                        key={"keyinput"}
                        onChange={(event) => this.textInputChanged(event)}
                        value={this.state.key}
                        labelText={"Chiave"}
                        formControlProps={{
                          fullWidth: false,
                          style: {
                            width: '82px',
                            backgroundColor: (this.state.products !== undefined && this.state.products.length > 0) ? "#66bb6a" : "#e8f5e9"
                          }
                        }}
                      />*/}
                      {/*<Select
                        filterPos="start"
                        filterProp="value"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            background: this.state.products === undefined ? "#e8f5e9" : "#66bb6a",
                            // Overwrittes the different states of border
                            borderColor: state.isFocused ? "yellow" : "green",
                            // Removes weird border around container
                            boxShadow: state.isFocused ? null : null,
                            "&:hover": {
                              // Overwrittes the different states of border
                              borderColor: state.isFocused ? "red" : "blue"
                            }
                          })
                        }}
                        menuIsOpen={this.state.menuIsOpen}
                        onMenuOpen={() => dashboard(this.menuOpened)}
                        onMenuClose={() => this.setState({ menuIsOpen: false })}
                        onChange={this.handleChangeCustomer}
                        name="form-field-customer"
                        options={this.state.customers_live}
                        value={this.state.selectedOptionCustomer}
                        placeholder="Numero chiave"
                      />*/}
                    </div>

                    <div style={{ flexGrow: '8', 'alignSelf': 'center', 'fontSize': '18px' }}>{this.state.customerName !== "" ? this.state.customerName : "Nessuna chiave selezionata"}</div>
                    <Button style={{ flexGrow: '1' }} onClick={this.sendOrder} variant="contained" color="primary">Invia ordine</Button>

                  </div>
                  <div style={{ height: '12px' }}></div>
                  {this.state.products !== undefined
                    ?
                    (<div style={{ backgroundColor: 'white', textAlign: 'center', maxHeight: "60vh", overflowY: "scroll" }}>
                      {this.state.products.map((prop, key) => {
                        return (
                          <table key={key} style={{ height: '62px', width: '99%', border: '2px solid lightgrey', borderBottom: key === this.state.products.length - 1 ? '1' : '0' }}>
                            <tbody>
                              <tr>
                                <td style={{ textAlign: 'left' }}>{prop.name}</td>
                                <td style={{ whiteSpace: 'nowrap', width: '64px' }}>{prop.price} €</td>
                                <td style={{ whiteSpace: 'nowrap', width: '1px' }}>
                                  <Button style={{ height: '62px', width: '62px', minWidth: '62px', margin: '14px' }} onClick={() => this.removeProduct(prop.id)} variant="contained" color="default">-</Button>
                                  {prop.quantity}
                                  <Button style={{ height: '62px', width: '62px', minWidth: '62px', margin: '14px' }} onClick={() => this.addProduct(prop.id)} variant="contained" color="default">+</Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      })}
                    </div>)
                    :
                    (
                      <div>{/*Nessun prodotto trovato*/}</div>
                    )
                  }
                </CardBody>
              </Card>
            </GridItem>

            <GridItem md={1}></GridItem>

            {/*
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>{tableTitle}</h4>
                <p className={classes.cardCategoryWhite} style={{ color: 'black' }}>
                  {tableSubTitle}
                </p>
              </CardHeader>
              <CardBody>
                {this.state.dataDashboard.customers_live.length > 0
                    ? <Table
                      pagination={true}
                      page={this.state.page}
                      rowsPerPage={this.state.rowsPerPage}
                      onArticleClick={this.onArticleClick}
                      articable={true}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      tableHeaderColor="info"
                      tableData={this.state.dataDashboard.customers_live}
                    />
                    : <div> Nessun associato all'interno del locale</div>
                }
              </CardBody>
            </Card>
          </GridItem>*/}
          </Grid>
          {/*customers_live={this.state.dataDashboard.customers_live.map((prop, key) => {
              return { value: prop.key, label: prop.key + " - " + prop.firstname };
            })}*/}
          {/*this.state.openBar &&
          <BuyerBarModal
            message={"Servizio bar NEXUS"}
            object={this.state.object} open={this.state.openBar} handleClose={this.handleCloseBar} />*/}
        </div>
      );
    }
    return placeholder;
  }
}

DashboardBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DashboardBar);
