import Button from "@material-ui/core/Button";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import React, { Component } from 'react';
import { login } from 'api/api';
import img_elLOGOnexusbar from "assets/img/nexusbar_logo.png";
import 'css/StyleCssCustom.css';
import { setLocalStorage, token, type } from 'utilities/helper';

export default class LoginView extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: "",
      tc: false,
      textNotification: "Errore durante l'accesso."
    };
  }

  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      6000
    );
  }

  textInputChanged_username = (event) => {
    this.setState({ username: event.target.value });
  }

  textInputChanged_password = (event) => {
    this.setState({ password: event.target.value });
  }

  callbackLoginSuccess = (response) => {
    if (response.type.toLowerCase() === "admin" || response.type.toLowerCase() === "bar" || response.type.toLowerCase() === "reception" || response.type.toLowerCase() === "reception2") {
      setLocalStorage(token, response.access_token);
      setLocalStorage(type, response.type.toLowerCase());
      window.location.reload();
    } else {
      this.showNotification("tc");
      this.setState({ textNotification: "Non puoi accedere con questo tipo di utente" })
    }
  }

  callbackLoginError = (error) => {
    //this.props.alert.error("Credenziali errate");

    this.showNotification("tc");
    this.setState({ textNotification: "Errore durante l'accesso." })
  }

  onClick_elLogin = (e) => {
    login(this.state.username, this.state.password, this.callbackLoginSuccess, this.callbackLoginError);
  }

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login(this.state.username, this.state.password, this.callbackLoginSuccess, this.callbackLoginError);
    }
  }

  render() {
    /*const styles = {
      cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "50%",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      }
    };

    const { classes } = this.props;*/
    // eslint-disable-next-line no-unused-vars
    let baseStyle = {};
    // eslint-disable-next-line no-unused-vars
    let layoutFlowStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle = { 'minHeight': '100vh', overflow: 'hidden' };
    }

    const style_background = {
      width: '100%',
      height: '100%',
    };
    const style_background_outer = {
      backgroundColor: '#212121',
      pointerEvents: 'none',
    };
    const style_LOGOnexusbar = {
      height: 'auto',
    };
    const style_LOGOnexusbar_outer = {
      pointerEvents: 'none',
      marginTop: '5%'
    };
    /*const elLOGOnexusbar = this.state.elLOGOnexusbar_visible ? (
      <div className='elLOGOnexusbar' style={style_LOGOnexusbar_outer}>
        <img style={style_LOGOnexusbar} src={img_elLOGOnexusbar} alt="" />

      </div>

    ) : null;*/
    const elLOGOnexusbar = (
      <div className='elLOGOnexusbar' style={style_LOGOnexusbar_outer}>
        <img style={style_LOGOnexusbar} src={img_elLOGOnexusbar} alt="" />
      </div>
    );
    /*const style_login = {
      display: 'block',
      fontSize: 19.2,
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif',
      textAlign: 'center',
    };
    const style_login_outer = {
      cursor: 'pointer',
    };*/

    return (
      <div className="LoginScreen" style={baseStyle}>
        <Snackbar
          place="tc"
          color="warning"
          icon={AddAlert}
          message={this.state.textNotification}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <div className="background">
          <div className='containerMinHeight elBackground' style={style_background_outer}>
            <div style={style_background} />
          </div>
        </div>
        <div className="layoutFlow" style={layoutFlowStyle}>
          {elLOGOnexusbar}
          <Card style={{ width: '85%', left: '7.5%' }}>
            <CardBody>
              <CustomInput
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                onChange={this.textInputChanged_username}
                value={this.state.username} />
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ type: "password" }}
                onChange={this.textInputChanged_password}
                value={this.state.password} />
              <div>{this.state.error}</div>
            </CardBody>
            <CardFooter style={{ alignSelf: 'center' }}>
              <Button variant="contained" onClick={this.onClick_elLogin} color="primary">Accedi</Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    )
  }
};
