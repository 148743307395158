// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader";
import { getObjects } from "api/api";
import Select from 'react-select';
const styles = {

};

class BuyerBarModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            object: props.object,
            refresh: true,
            selectedOptionCustomer: props.object ? props.object.key ? [{ value: this.props.object.key, label: this.props.object.key }] : undefined : undefined
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            object: props.object,
            refresh: true,
            selectedOptionCustomer: props.object ? props.object.key ? [{ value: this.props.object.key, label: this.props.object.key }] : undefined : undefined
        })
    }

    callback = (response) => {
        this.setState({ products: response.data.data, refresh: false })
    }

    callbackError = (error) => {
        this.setState({ products: [], refresh: false });
    }

    getArticle = () => {
        var endpoint = "products/key/";
        if (this.props.extra)
            endpoint = "products/extra/key/";
        if (this.state.object)
            getObjects(endpoint + this.state.object.key, this.callback, this.callbackError);
        else
            getObjects(endpoint + 0, this.callback, this.callbackError);

    }

    addProduct = (id) => {
        var endpoint = "products/";
        if (this.props.extra)
            endpoint = "products/extra/";

        getObjects(endpoint + id + "/key/" + this.state.object.key + "/add", this.callback, this.callbackError);
    }

    removeProduct = (id) => {
        var endpoint = "products/";
        if (this.props.extra)
            endpoint = "products/extra/";

        getObjects(endpoint + id + "/key/" + this.state.object.key + "/remove", this.callback, this.callbackError);
    }

    handleChangeCustomer = (selectedOptionCustomer) => {

        var object = { key: '0' };
        if (this.state.object) {
            object = this.state.object;
        }
        object.key = selectedOptionCustomer.value;

        this.setState({
            object: object,
            selectedOptionCustomer: [{ value: selectedOptionCustomer.value, label: selectedOptionCustomer.label }],
            refresh: true
        });
    }


    render() {
        if (this.state.refresh) {
            this.getArticle();
        }

        var halfHeight = (window.innerHeight / 1.8).toString() + "px";

        var modalRender = (null);
        if (this.state.object !== null) {
            modalRender = (
                <div style={{ width: '100wv', height: '100wh' }}>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.props.open}
                        onClose={this.props.handleClose}
                        style={{}}>
                        <Card style={{
                            position: 'absolute',
                            marginTop: '0px',
                            marginBottom: '0px',
                            left: '50%',
                            top: '5%',
                            width: '500px',
                            maxHeight: '60wh',
                            transform: 'translate(-50%, 0%)',
                        }}>
                            <CardHeader color="primary">
                                <p className={this.props.classes.cardTitleWhite}>{this.props.message}</p>
                            </CardHeader>
                            <CardBody style={{ height: halfHeight }}>
                                <div>Numero chiave</div>
                                <Select
                                    onChange={this.handleChangeCustomer}
                                    name="form-field-customer"
                                    options={this.props.customers_live}
                                    value={this.state.selectedOptionCustomer}
                                    isDisabled={true}
                                    placeholder="Numero chiave"
                                />
                                <div style={{ height: '12px' }}></div>
                                {this.state.products !== undefined
                                    ?
                                    (<div style={{ backgroundColor: 'white', textAlign: 'center', height: "85%", overflowY: "scroll" }}>
                                        {this.state.products.map((prop, key) => {
                                            return (
                                                <table style={{ height: '40px', width: '99%', border: '2px solid lightgrey', borderBottom: key === this.state.products.length - 1 ? '1' : '0' }}>
                                                    <tbody>
                                                        <td>
                                                            <tr>
                                                                {prop.name}
                                                            </tr>
                                                        </td>
                                                        <td style={{ whiteSpace: 'nowrap', width: '64px' }}>
                                                            <tr>
                                                                {prop.price} €
                                                            </tr>
                                                        </td>
                                                        <td style={{ whiteSpace: 'nowrap', width: '1px' }}>
                                                            <tr>
                                                                <Button style={{ width: '40px', minWidth: '40px', marginRight: '14px' }} onClick={() => this.removeProduct(prop.id)} variant="contained" color="white">-</Button>
                                                                {prop.quantity}
                                                                <Button style={{ width: '40px', minWidth: '40px', marginLeft: '14px' }} onClick={() => this.addProduct(prop.id)} variant="contained" color="white">+</Button>
                                                            </tr>
                                                        </td>
                                                    </tbody>
                                                </table>
                                            );
                                        })}
                                    </div>)
                                    :
                                    (
                                        <div>Nessun prodotto trovato</div>
                                    )
                                }
                            </CardBody>
                            <CardFooter>
                                <Button style={{ width: '100%' }} onClick={this.props.handleClose} variant="contained" color="white">Chiudi</Button>
                            </CardFooter>
                        </Card>
                    </Modal>
                </div>);
        }

        return (modalRender);
    }
}

export default withStyles(styles)(BuyerBarModal);