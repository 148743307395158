/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutesAdmin from "routes/dashboardAdmin.jsx";
import dashboardRoutesBar from "routes/dashboardBar.jsx";
import dashboardRoutesReception from "routes/dashboardReception";
import dashboardRoutesReception2 from "routes/dashboardReception2";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/nexusbar_logo.png";
import { getLocalStorage, token, type } from "utilities/helper";
import LoginView from "views/Login/LoginView";

const switchRoutes = (
  <Switch>
    {getLocalStorage(type) == "admin"
      ? dashboardRoutesAdmin.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.to} key={key} />;
        return <Route path={prop.path} component={prop.component} key={key} />;
      })
      : getLocalStorage(type) == "reception" ?
        dashboardRoutesReception.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.to} key={key} />;
          return <Route path={prop.path} component={prop.component} key={key} />;
        })
        : getLocalStorage(type) == "reception2" ?
          dashboardRoutesReception2.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.to} key={key} />;
            return <Route path={prop.path} component={prop.component} key={key} />;
          })
          : getLocalStorage(type) == "bar" ?
            dashboardRoutesBar.map((prop, key) => {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return <Route path={prop.path} component={prop.component} key={key} />;
            })
            : "error"}
  </Switch>
);

class App extends React.Component {
  state = {
    mobileOpen: false
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentDidUpdate(e) {
    try {
      if (e.history.location.pathname !== e.location.pathname) {
        this.refs.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    } catch (error) {

    }
  }
  render() {
    const { classes, ...rest } = this.props;
    var onePageApp = null;

    /*
    Senza token reinderizza pagina di login
    */
    var token1 = getLocalStorage(token);
    if (token1 === "0" || token1 === null || token1 === "null") {
      onePageApp = (<LoginView />);
    } else {
      var routes;
      switch (getLocalStorage(type)) {
        case "admin":
          routes = dashboardRoutesAdmin;
          break;
        case "bar":
          routes = dashboardRoutesBar;
          break;
        case "reception":
          routes = dashboardRoutesReception;
          break;
        case "reception2":
          routes = dashboardRoutesReception2;
          break;
      }
      onePageApp = (<div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"nexusbar"}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          {/* <Header
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          /> */}
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
          {this.getRoute() ? <Footer /> : null}
        </div>
      </div>);
    }

    return (
      onePageApp
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
