// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from "components/Card/Card.jsx";

const styles = {

};

class ConfirmModalv2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            object: props.object,
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            object: props.object,
        })
    }

    callback = (response) => {
        this.setState({ srcImage: this.state.srcImage + response.data })
    }

    callbackError = (error) => {
        alert(error);
    }

    render() {

        var modalRender = (null);
        if (this.state.object !== null) {
            modalRender = (
                <div style={{ width: '100wv', height: '100wh' }}>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.props.open}
                        onClose={this.props.handleClose}
                        style={{}}>
                        <Card style={{
                            position: 'absolute',
                            marginTop: '0px',
                            marginBottom: '0px',
                            left: '50%',
                            top: '50%',
                            width: '700px',
                            minHeight: (this.props.object.products !== undefined && this.props.object.products.length > 0) ? '400px' : '100px',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <CardBody>
                                <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                                    {this.props.message} {this.props.object.customer_2 !== "" && (" e " + this.props.object.customer_2)}
                                    {this.props.object.extra !== "" && <div style={{ border: '1px solid lightgrey' }}><p>Gli extra sono : {this.props.object.extra}</p></div>}
                                    {this.props.object.products !== undefined && this.props.object.products.length > 0
                                        ?
                                        (
                                            <div>
                                                <div><p>I prodotti acquistati sono i seguenti</p></div>

                                                <table style={{ height: '40px', width: '99%', border: '2px solid lightgrey' }}>
                                                    <tr>
                                                        <td align="left">
                                                            Nome prodotto
                                            </td>
                                                        <td style={{ whiteSpace: 'nowrap', width: '64px' }}>
                                                            Prezzo
                                            </td>
                                                        <td style={{ whiteSpace: 'nowrap', width: '64px' }}>
                                                            Quantità
                                            </td>
                                                    </tr>
                                                </table>


                                                <div style={{ backgroundColor: 'white', textAlign: 'center', maxHeight: "250px", overflowY: "scroll" }}>
                                                    <table style={{ height: '40px', width: '99%', border: '2px solid lightgrey', borderBottom: '1' }}>
                                                        {this.props.object.products && this.props.object.products.map((prop, key) => {
                                                            return (
                                                                <tr style={{ 'border-bottom': '1px solid red' }}>
                                                                    <td align="left">
                                                                        {prop.name}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap', width: '64px' }}>
                                                                        {prop.price} €
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap', width: '64px' }}>
                                                                        {parseInt(prop.pivot.quantity)}
                                                                    </td>
                                                                </tr>

                                                            );
                                                        })
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <div>Nessun prodotto acquistato</div>
                                    }
                                    <div style={{ marginTop: '12px', fontWeight: 'bold', fontSize: '20px' }}>Resoconto spese: {this.props.object.total} €</div>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button style={{ width: '45%' }} onClick={this.props.handleClose} variant="contained" color="white">No</Button>
                                <Button style={{ width: '45%' }} onClick={this.props.handleConfirm} variant="contained" color="primary">Si</Button>
                            </CardFooter>
                        </Card>
                    </Modal>
                </div>);
        }

        return (modalRender);
    }
}

export default withStyles(styles)(ConfirmModalv2);