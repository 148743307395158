import React from "react";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CustomModalObject from "components/Modals/CustomModalObject.jsx";
import { getObjects, postObjects, putObjects, deleteObjects } from 'api/api';
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { ProductModel } from 'model/Models.jsx';
import { filterByField } from 'utilities/helper';
import ConfirmModal from "components/Modals/ConfirmModal";

const modalTitle = "Nuovo prodotto";
const modalSubTitle = "Aggiungi un nuovo prodotto";
const placeholderText = "Nessun prodotto trovato";
const tableSubTitle = "Elenco dei prodotti";
const tableTitle = "Prodotti";
const objectType = ProductModel.type;
function getNewModel() {
    return new ProductModel(-1, "", "");
}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class ProductPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 20,
            open: false,
            dataObjects: null,
            refresh: true,
            object: getNewModel(),
            refreshModal: false
        }
    }

    handleOpen = () => {
        this.setState({
            open: true,
            object: getNewModel()
        });
    };

    handleClose = () => {
        this.setState({ open: false, openDelete: false, refresh: true });
    };

    handleError = (error) => {
        console.error(error.response);
    };

    onEditClick = (cell, row, event) => {
        var filtered = filterByField(this.state.dataObjects, "id", row.rowData[0])[0];
        this.setState({
            open: true,
            object: filtered,
            refreshModal: true
        });
    };

    handleChangePage = (event, page) => {
        this.setState({
            page: page
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    saveObject = (object) => {
        if (object.id === -1) {
            postObjects(object, objectType, this.handleClose, this.handleError);
        } else {
            putObjects(object, objectType, this.handleClose, this.handleError);
        }
    };

    callbackGetObjects = (objects) => {
        this.setState({
            dataObjects: objects.data.data,
            refresh: false
        });
    }

    callbackErrorGetObjects = () => {
        this.setState({
            refresh: false
        });
    }

    callbackErrorMessage = (response) => {
        alert("Errore durante la cancellazione del prodotto, il prodotto potrebbe essere utilizzato in questo momento.");
    }

    handleConfirmDelete = () => {
        deleteObjects("products/" + this.state.object.id, () => this.setState({ openDelete: false, refresh: true }), this.callbackErrorMessage);
    };

    onDeletableClick = (cell, row, event) => {
        var filtered = filterByField(this.state.dataObjects, "id", row.rowData[0])[0];
        this.setState({
            openDelete: true,
            object: filtered
        });
    }

    render() {
        const { classes } = this.props;
        if (this.state.refresh) {
            getObjects(objectType, this.callbackGetObjects, this.callbackErrorGetObjects)
        }

        var placeholder = (<div>{placeholderText}</div>);

        if (this.state.dataObjects !== null && this.state.dataObjects.length > 0) {
            placeholder = (
                <Grid container>
                    <GridItem xs={0} sm={0} md={1}></GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="warning">
                                <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>{tableTitle}</h4>
                                <p className={classes.cardCategoryWhite} style={{ color: 'black' }}>
                                    {tableSubTitle}
                                </p>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    pagination={true}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onEditClick={this.onEditClick}
                                    editable={true}
                                    deletable={true}
                                    onDeletableClick={this.onDeletableClick}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    tableHeaderColor="info"
                                    tableData={this.state.dataObjects}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={0} sm={0} md={1.5}></GridItem>
                </Grid>
            )
        }
        return (
            <div>
                <Snackbar
                    place="tc"
                    color="warning"
                    icon={AddAlert}
                    message="Errore, non sei autorizzato ad effettuare questa operazione."
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                />
                {placeholder}
                <Button onClick={this.handleOpen} variant="fab" color="primary" aria-label="Add" className={classes.button} style={{ position: 'fixed', bottom: '2vh', right: '2vw' }}>
                    <AddIcon />
                </Button>
                {this.state.openDelete && <ConfirmModal message={"Vuoi proseguire con la cancellazione del prodotto : " + this.state.object.name} object={this.state.object} open={this.state.openDelete} handleClose={this.handleClose} handleConfirm={this.handleConfirmDelete} />}
                <CustomModalObject title={modalTitle} subtitle={modalSubTitle} saveButtonClick={this.saveObject} object={this.state.object} open={this.state.open} handleClose={this.handleClose} />
            </div>
        )
    };
}

export default withStyles(styles)(ProductPage);
