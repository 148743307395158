// @material-ui/icons
import HighlightOff from "@material-ui/icons/HighlightOff";
import RoomService from "@material-ui/icons/RoomService";
import Store from "@material-ui/icons/Store";
import React from 'react';
import { Redirect } from 'react-router';
// core components/views
import { setLocalStorage, token } from "utilities/helper";
import ProductPage from "views/Product/ProductPage";
import RoomPage from "views/Room/RoomPage";

function logout() {
  setLocalStorage(token, null);
  return <Redirect to='/dashboard' />;
}

const dashboardRoutesAdmin = [
  /*{
    path: "/dashboard",
    sidebarName: "Bacheca",
    navbarName: "Nexusbar's dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: "/registrations",
    sidebarName: "Gestione",
    navbarName: "Gestione",
    icon: Event,
    component: RegistrationPage
  },
  {
    path: "/customers",
    sidebarName: "Associati",
    navbarName: "Associati",
    icon: Person,
    component: CustomerPage
  },*/
  {
    path: "/products",
    sidebarName: "Prodotti",
    navbarName: "Prodotti",
    icon: Store,
    component: ProductPage
  },
  {
    path: "/rooms",
    sidebarName: "Camere",
    navbarName: "Camere",
    icon: RoomService,
    component: RoomPage
  },
  {
    path: "/logout",
    sidebarName: "Esci",
    navbarName: "Logout",
    icon: HighlightOff,
    component: logout
  },
  { redirect: true, path: "/", to: "/products", navbarName: "Redirect" }
];

export default dashboardRoutesAdmin;
