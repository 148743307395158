import React from "react";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import AddIcon from '@material-ui/icons/Add';
import Print from "@material-ui/icons/Print";
import Button from '@material-ui/core/Button';
import CustomModalObject from "components/Modals/CustomModalObject.jsx";
import { getObjects, postObjects, putObjects } from 'api/api';
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { CustomerModel } from 'model/Models.jsx';
import { filterByField } from 'utilities/helper';
import * as jsPDF from 'jspdf';
import _ from 'lodash';

var moment = require('moment');
const modalTitle = "Nuovo associato";
const modalSubTitle = "Aggiungi un nuovo associato";
const placeholderText = "Nessun associato trovato";
const tableSubTitle = "";
const tableTitle = "Elenco registro soci";
const objectType = CustomerModel.type;
var dateNow;
var dateExpireNow;
function getNewModel() {
    return new CustomerModel(-1, '', '', '', '', '', 'gg/mm/aaaa', '', dateNow, dateExpireNow, 'Nexus Ass. CULT.')
}

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class CustomerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 50,
            open: false,
            dataObjects: null,
            refresh: true,
            object: getNewModel(),
            refreshModal: false,
            fromCardInput: "",
            toCardInput: ""
        }
    }

    handleOpen = () => {
        dateNow = moment().format("YYYY-MM-DD");
        dateExpireNow = moment().add(1, 'year').format("YYYY-MM-DD");
        this.setState({
            open: true,
            object: getNewModel()
        });
    };

    handleClose = () => {
        this.setState({ open: false, refresh: true });
    };

    handleError = (error) => {
        console.error(error.response);
    };

    onEditClick = (cell, row, event) => {
        var filtered = filterByField(this.state.dataObjects, "id", row.rowData[0])[0];
        this.setState({
            open: true,
            object: filtered,
            refreshModal: true
        });
    };

    handleChangePage = (event, page) => {
        this.setState({
            page: page
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    saveObject = (object) => {
        if (object.id === -1) {
            postObjects(object, objectType, this.handleClose, this.handleError);
        } else {
            putObjects(object, objectType, this.handleClose, this.handleError);
        }
    };

    callbackGetObjects = (objects) => {
        var count = 0;
        try {
            var lastcard = objects.data.data[count].card;

            while (lastcard === "" && count < objects.data.data.length) {
                count = count + 1;
                lastcard = objects.data.data[count].card;
            }
        } catch ($ex) {

        }

        try {
            objects.data.data.length > 0 && this.setState({
                dataObjects: objects.data.data.length > 0 ? objects.data.data : [],
                page: 1,
                refresh: false,
                lastcard: lastcard
            });
        } catch ($ex) {

        }
    }

    callbackErrorGetObjects = (response) => {
        alert(response.message);
        this.setState({
            refresh: false
        });
    }

    onSearchChange = (searchText) => {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.tick(searchText), 10);
    };

    tick = (searchText) => {
        clearInterval(this.interval);
        if (searchText.length !== 0) {
            getObjects(objectType + "?filter=fullname:like:%" + searchText + "%", this.callbackGetObjects, this.callbackErrorGetObjects)
        } else {
            getObjects(objectType, this.callbackGetObjects, this.callbackErrorGetObjects)
        }
    }

    rowInsertDoc = (doc, height, item, key) => {
        doc.text(15, height, item.surname + " " + item.firstname);
        doc.text(70, height, item.city + " " + item.address);
        doc.text(150, height, item.dateexp);
        doc.text(180, height, item.card);
        doc.line(200, height + 2, 10, height + 2)
    }

    printReport = (responseJSON) => {
        let response = _.orderBy(responseJSON.data.data, ['card'], ['asc']);
        var imgData = require('assets/img/associati_print_header.png');
        var doc = new jsPDF('', '', 'a4', true);
        var height = 0;
        var rowHeight = 5;

        doc.setTextColor(150, 0, 0);
        doc.setFontType('bold');
        doc.setFontSize(22);
        doc.addImage(imgData, 'JPEG', 0, 0, 220, 40)
        height = height + 50;
        doc.setTextColor(150, 0, 0);
        doc.setFontType('bold');
        doc.setFontSize(22);
        doc.text(77, height, 'Nexus Ass. Cult.');
        height = height + 10;
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(10, height, "Stato di decorrenza: ");
        doc.setTextColor(0, 0, 150);
        doc.setFontType('italic');
        doc.text(45, height, "in corso di scadenza e di validità");

        doc.setTextColor(0, 0, 150);
        doc.setFontType('')
        doc.setFontSize(10);
        doc.setFontType('bold');

        doc.setTextColor(0, 0, 0);
        height = height + 10;
        doc.text(15, height, 'Cognome Nome');
        doc.text(70, height, 'Indirizzo - Residenza');
        doc.text(150, height, 'Data di scadenza');
        doc.text(180, height, 'N° Tessera');

        doc.setTextColor(0, 0, 150);
        doc.setFontType('italic');
        doc.setFontSize(9);

        doc.setDrawColor(224, 224, 224)
        response.map((item, key) => {
            if (height >= doc.internal.pageSize.getHeight() - 10) {
                doc.addPage();
                doc.setDrawColor(224, 224, 224)
                height = 10;
            } else {
                height = height + rowHeight;
            }
            this.rowInsertDoc(doc, height, item, key);
            return null
        });

        var string = doc.output('datauristring');
        var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();

    }

    getReportData = () => {
        var endpoint = objectType + "?filter=";
        if (this.state.fromCardInput !== "") endpoint = endpoint + "card:>=:NX" + this.state.fromCardInput.padStart(6, '0');
        if (this.state.fromCardInput !== "" && this.state.toCardInput !== "") endpoint = endpoint + ";";
        if (this.state.toCardInput !== "") endpoint = endpoint + "card:<=:NX" + this.state.toCardInput.padStart(6, '0');
        getObjects(endpoint, this.printReport, this.callbackErrorGetObjects);
    }

    render() {
        const { classes } = this.props;
        if (this.state.refresh) {
            getObjects(objectType, this.callbackGetObjects, this.callbackErrorGetObjects)
        }

        var placeholder = (<div>{placeholderText}</div>);

        placeholder = (
            <div>
                <Grid container>
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{ width: '100%' }}>
                            <Card onClick={this.getReportData} style={{ height: '120px', width: '120px', alignItems: 'center', display: 'table-cell', verticalAlign: 'middle', backgroundColor: '#66bb6a' }}>
                                <div style={{ textAlign: 'center' }}><Print style={{ fontSize: '48px', color: 'black' }} /></div>
                                <div style={{ textAlign: 'center', color: 'black' }}>STAMPA</div>
                            </Card>
                            <Card style={{ padding: '8px', left: '24px', height: '120px', width: '240px', alignItems: 'center', display: 'table-cell', verticalAlign: 'middle' }}>
                                <CustomInput
                                    key={"fromCardInput"}
                                    onChange={(event) => this.setState({ fromCardInput: event.target.value })}
                                    value={this.state.fromCardInput}
                                    labelText={"Da numero tessera"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <CustomInput
                                    key={"toCardInput"}
                                    onChange={(event) => this.setState({ toCardInput: event.target.value })}
                                    value={this.state.toCardInput}
                                    labelText={"A numero tessera"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </Card>
                        </div>
                    </GridItem>
                </Grid>
                <Grid container>
                    <GridItem xs={false} sm={false} md={1}></GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="warning">
                                <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>{tableTitle}</h4>
                                <p className={classes.cardCategoryWhite} style={{ color: 'black' }}>
                                    {tableSubTitle}
                                </p>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    pagination={true}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onEditClick={this.onEditClick}
                                    editable={true}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    tableHeaderColor="info"
                                    tableData={this.state.dataObjects}
                                    onSearchChange={this.onSearchChange}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={false} sm={false} md={1}></GridItem>
                </Grid>
            </div>
        )
        return (
            <div>
                <Snackbar
                    place="tc"
                    color="warning"
                    icon={AddAlert}
                    message="Errore, non sei autorizzato ad effettuare questa operazione."
                    open={this.state.tc}
                    closeNotification={() => this.setState({ tc: false })}
                    close
                />
                {placeholder}
                <Button onClick={this.handleOpen} variant="fab" color="primary" aria-label="Add" className={classes.button} style={{ position: 'fixed', bottom: '2vh', right: '2vw' }}>
                    <AddIcon />
                </Button>
                <CustomModalObject title={modalTitle} subtitle={modalSubTitle} saveButtonClick={this.saveObject} object={this.state.object} open={this.state.open} handleClose={this.handleClose} lastCard={this.state.lastcard} />
            </div>
        )
    };
}

export default withStyles(styles)(CustomerPage);
