// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Person from "@material-ui/icons/Person";
import React from 'react';
import { Redirect } from 'react-router';
// core components/views
import DashboardReceptionPage from "views/Dashboard/DashboardReception.jsx";
import { setLocalStorage, token } from "utilities/helper";
import EconomicReport from "views/EconomicReport/EconomicReport";

function logout() {
  setLocalStorage(token, null);
  return <Redirect to='/dashboard' />;
}

const dashboardRoutesReception = [
  {
    path: "/dashboard",
    sidebarName: "Reception",
    navbarName: "Reception",
    icon: Dashboard,
    component: DashboardReceptionPage
  },
  /*{
    path: "/registrations",
    sidebarName: "Associati",
    navbarName: "Associati",
    icon: Event,
    component: RegistrationPage
  },*/
  {
    path: "/economic_report",
    sidebarName: "Bilancio economico",
    navbarName: "Bilancio economico",
    icon: Person,
    component: EconomicReport
  },
  /*{
    path: "/customers",
    sidebarName: "Associati",
    navbarName: "Associati",
    icon: Person,
    component: CustomerPage
  },*/
  {
    path: "/logout",
    sidebarName: "Esci",
    navbarName: "Logout",
    icon: HighlightOff,
    component: logout
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutesReception;
