import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from '@material-ui/core/Button';
import Print from "@material-ui/icons/Print";
import Modal from '@material-ui/core/Modal';
import CardIcon from "components/Card/CardIcon.jsx";
import Person from "@material-ui/icons/Person";
import CardFooter from "components/Card/CardFooter.jsx";
import * as jsPDF from 'jspdf';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { getObjects } from 'api/api';

class EconomicReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      refresh: true
    };
  }

  callbackErrorGetObjects = (error) => {
    alert("Attenzione, qualcosa è andato storto");
    this.setState({
      refresh: false,
      open: false
    });
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  }

  headerInsertDoc = (doc, height) => {
    doc.setTextColor(0, 0, 200);
    doc.setFontType('italic');
    doc.setFontSize(10);
    doc.text(10, height, 'N');
    doc.text(30, height, 'POS');
    doc.text(55, height, 'NOMINATIVO SOCIO');
    doc.text(105, height, 'CHIAVE');
    doc.text(140, height, 'CONTRIBUTO');
    doc.text(180, height, 'CAMERA');

  }

  rowInsertDoc = (doc, height, item, key) => {

    doc.setTextColor(0, 0, 0);
    doc.setFontType('normal');
    doc.setFontSize(9);
    doc.text(10, height, (key + 1).toString());
    doc.text(30, height, item.pos ? 'Si' : '');
    doc.text(55, height, item.customer_2 !== '' ? item.customer_1 + ' - ' + item.customer_2 : item.customer_1);
    doc.text(105, height, item.key);
    doc.text(140, height, item.amount.toString());
    doc.text(180, height, item.room !== undefined ? item.room.toString() : '');
  }

  printReport = () => {
    var doc = new jsPDF('a4');
    var height = 20;
    var rowHeight = 5;

    doc.setTextColor(150, 0, 0);
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(60, height, 'BILANCIO GIORNALIERO');
    height = height + 10;
    doc.setFontSize(16);
    doc.text(90, height, new Date().getDate().toString() + "/" + (new Date().getMonth() + 1).toString() + "/" + new Date().getFullYear().toString());

    doc.setTextColor(0, 0, 150);
    doc.setFontType('')
    doc.setFontSize(12);

    height = height + 20;
    doc.text(20, height, 'Num. C.P. ');
    doc.text(60, height, 'Num. Singoli');
    doc.text(110, height, 'Num. Singole');
    doc.text(160, height, 'Tot. Presenze');
    height = height + 10;
    doc.setTextColor(0, 0, 0);
    doc.text(20, height, this.state.data.registration_coppie_today.length.toString());
    doc.text(60, height, this.state.data.registration_singoli_today.length.toString());
    doc.text(110, height, this.state.data.registration_singole_today.length.toString());
    doc.text(160, height, (
      (this.state.data.registration_coppie_today.length * 2) +
      this.state.data.registration_singoli_today.length +
      this.state.data.registration_singole_today.length).toString());

    height = height + 20;
    doc.setTextColor(0, 0, 150);
    doc.text(20, height, 'Tot. Soci');
    doc.text(60, height, 'Tot. Bar');
    doc.text(110, height, 'Tot. Pos');
    doc.text(160, height, 'Tot. in Cassa');
    height = height + 10;
    doc.setTextColor(0, 0, 0);
    doc.text(20, height, this.state.data.customer_paying.toString() + " EUR");
    doc.text(60, height, this.state.data.consumation_bar.toString() + " EUR");
    doc.text(110, height, this.state.data.customer_pos.toString() + " EUR");
    doc.text(160, height, parseFloat(this.state.data.total).toFixed(2).toString() + " EUR");

    doc.addPage();

    height = 20;
    doc.setTextColor(150, 0, 0);
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(77, height, 'LISTA COPPIE');
    height = height + 10;
    this.headerInsertDoc(doc, height);
    this.state.data.registration_coppie_today.map((item, key) => {
      if (height >= doc.internal.pageSize.getHeight() - 10) {
        doc.addPage();
        height = 10;
      } else {
        height = height + rowHeight;
      }
      this.rowInsertDoc(doc, height, item, key);
      return null
    });

    if (height >= doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      height = 10;
    } else {
      height = height + 20;
    }
    doc.setTextColor(150, 0, 0);
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(76, height, 'LISTA SINGOLI');
    if (height >= doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      height = 10;
    } else {
      height = height + 10;
    }
    this.headerInsertDoc(doc, height);
    this.state.data.registration_singoli_today.map((item, key) => {
      if (height >= doc.internal.pageSize.getHeight() - 10) {
        doc.addPage();
        height = 10;
      } else {
        height = height + rowHeight;
      }
      this.rowInsertDoc(doc, height, item, key);
      return null
    });

    if (height >= doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      height = 10;
    } else {
      height = height + 20;
    }
    doc.setTextColor(150, 0, 0);
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(74, height, 'LISTA SINGOLE');
    if (height >= doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      height = 10;
    } else {
      height = height + 10;
    }
    this.headerInsertDoc(doc, height);
    this.state.data.registration_singole_today.map((item, key) => {
      if (height >= doc.internal.pageSize.getHeight() - 10) {
        doc.addPage();
        height = 10;
      } else {
        height = height + rowHeight;
      }
      this.rowInsertDoc(doc, height, item, key);
      return null
    });

    var string = doc.output('datauristring');
    var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
    var x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();

  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  callbackPrint = (result) => {
    this.setState({
    });
  };

  callbackGetObjects = (result) => {
    this.setState({
      data: result.data,
      refresh: false,
      open: false
    });
  };

  deleteAll = () => {
    getObjects("delete_all", this.callbackGetObjects, this.callbackErrorGetObjects);
  }

  render() {
    const { classes } = this.props;
    if (this.state.refresh) {
      getObjects("print_report", this.callbackGetObjects, this.callbackErrorGetObjects);
    }
    var placeholder = (<div>Caricamento bilancio economico</div>);

    if (this.state.data !== null) {

      placeholder = (
        <div>
          <Grid container>
            <GridItem xs={12} sm={6} md={3}>
              <Card >
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Numero soci paganti presenti</p>
                  <h3 className={classes.cardTitle}>{this.state.data.customer_paying} €</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="rose" stats icon>
                  <CardIcon color="rose">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Consumi del bar</p>
                  <h3 className={classes.cardTitle}>{this.state.data.consumation_bar} €</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="yellow" stats icon>
                  <CardIcon color="yellow">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>POS</p>
                  <h3 className={classes.cardTitle}>{this.state.data.customer_pos} €</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="orange" stats icon>
                  <CardIcon color="orange">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Cassa</p>
                  <h3 className={classes.cardTitle}>{this.state.data.total} €</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem xs={12} sm={12} md={12}>
              <Card onClick={this.handleOpen} style={{ backgroundColor: '#f44336', height: '200px', width: '200px', alignItems: 'center', display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={{ textAlign: 'center' }}>CANCELLA TUTTO IL GIORNALIERO</div>
              </Card>
              <Card onClick={this.printReport} style={{ left: '32px', height: '200px', width: '200px', alignItems: 'center', display: 'table-cell', verticalAlign: 'middle', backgroundColor: '#66bb6a' }}>
                <div style={{ textAlign: 'center' }}><Print style={{ fontSize: '48px', color: 'black' }} /></div>
                <div style={{ textAlign: 'center', color: 'black' }}>STAMPA</div>
              </Card>
            </GridItem>
          </Grid>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
            style={{}}>
            <Card style={{
              position: 'absolute',
              marginTop: '0px',
              marginBottom: '0px',
              left: '50%',
              top: '50%',
              width: '700px',
              minHeight: '100px',
              transform: 'translate(-50%, -50%)',
            }}>
              <CardBody>
                <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                  Attenzione, vuoi davvero cancellare tutto il giornaliero?
                </div>
              </CardBody>
              <CardFooter>
                <Button style={{ width: '45%' }} onClick={this.handleClose} variant="contained" color="white">No</Button>
                <Button style={{ width: '45%' }} onClick={this.deleteAll} variant="contained" color="primary">Si</Button>
              </CardFooter>
            </Card>
          </Modal>
        </div>
      );
    }
    return placeholder;
  }
}

EconomicReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(EconomicReport);
