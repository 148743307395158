import axios from 'axios';
import { getLocalStorage, token, getStringify } from 'utilities/helper';
const urlProxy = "https://api.nexusnaturist.it/";
//const urlProxy = "http://api.nexusbar.com/";
//const urlProxy = "http://192.168.1.251/";

function headerAuthorization() {
    var bearerToken = 'Bearer ' + getLocalStorage(token);
    return {
        'Authorization': bearerToken,
        'Content-Type': 'application/json',
    };
}

export function login(username, password, callback, errorCallback) {
    var data = {
        username: username,
        password: password
    };

    axios({
        method: 'POST',
        url: urlProxy + 'login',
        data: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}

export function getMe(callback) {
    axios({
        method: 'GET',
        url: urlProxy + 'me',
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.error(error);
        })
}

export function dashboard(callback) {
    axios({
        method: 'GET',
        url: urlProxy + 'dashboard',
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.error(error);
        })
}

export function genericCall(method, url, body, callback, errorCallback) {
    axios({
        method: method,
        url: urlProxy + url,
        headers: headerAuthorization(),
        data: body,
    })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}

/* Object api */
//get all specify Objects
export function getObjects(objectName, callback, errorCallback) {
    axios({
        method: 'GET',
        url: urlProxy + objectName,
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}

//get all specify Objects
export function deleteObjects(objectName, callback, errorCallback) {
    axios({
        method: 'DELETE',
        url: urlProxy + objectName,
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}

//post new Object
export function postObjects(object, objectType, callback, errorCallback) {
    axios({
        method: 'POST',
        url: urlProxy + objectType,
        data: getStringify(object),
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}

//edit Object
export function putObjects(object, objectType, callback, errorCallback) {
    axios({
        method: 'PUT',
        url: urlProxy + objectType + '/' + object.id,
        data: getStringify(object),
        headers: headerAuthorization()
    })
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            console.error(error);
            errorCallback(error);
        })
}