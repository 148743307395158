// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import HighlightOff from "@material-ui/icons/HighlightOff";
import React from 'react';
import { Redirect } from 'react-router';
// core components/views
import DashboardBar from "views/Dashboard/DashboardBar.jsx";
import { setLocalStorage, token } from "utilities/helper";

function logout() {
  setLocalStorage(token, null);
  return <Redirect to='/dashboard' />;
}

const dashboardRoutesBar = [
  {
    path: "/dashboard",
    sidebarName: "Bacheca",
    navbarName: "Nexusbar's dashboard",
    icon: Dashboard,
    component: DashboardBar
  },
  {
    path: "/logout",
    sidebarName: "Esci",
    navbarName: "Logout",
    icon: HighlightOff,
    component: logout
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutesBar;
