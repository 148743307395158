import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import Assignment from "@material-ui/icons/Assignment";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Store from "@material-ui/icons/Store";
import LocationOn from "@material-ui/icons/LocationOn";
import Subtitles from "@material-ui/icons/Subtitles";
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
//import TablePaginationActions from "@material-ui/core/TablePaginationActions";
// core components
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import { translateField, displayColumns, filteredColumns } from "utilities/helper";

/**
  Se si ha intenzione di usare la pagination settare la props "pagination": true altrimenti non valorizzare
  Se si inserisci la props "pagination": true dobbiamo valorizzare anche i valori inziali di page e rowsPerPage con i valori iniziali della tabella (0 e 5)
  e valorizzare anche handleChangePage e handleChangeRowsPerPage con le callback che puntano al setState per cambiare: Data, page e rowsPerPage (vedi Trucks)

  handleChangePage = (event, page) => {
        this.setState({
            page: page
        });
  };

  handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
  };

*/

// const customStyles = {
//   BusinessAnalystRow: {
//     '& td': { backgroundColor: "#F00" }
//   },
//   NameCell: {
//     fontWeight: 900
//   },
// };
class CustomTable extends React.Component {

  clearFilter = () => {
    console.log("prova")
  }

  render() {
    //onCellClickCallBack
    const { deletable, onDeletableClick, classes, tableData, imageable, editable, localize, organize, onImageClick, onSearchChange, onEditClick, onLocalizeClick, onOrganizeClick, noOption, rowsPerPageOptions, rowsPerPage, hideId, title, detailable, onDetailableClick, finishable, onFinishClick, articable, onArticleClick } = this.props;
    var tableHead = null;
    if (tableData !== undefined) {

      try {
        if (tableData.constructor === Array)
          tableHead = Object.keys(tableData[0]);

        tableHead = tableHead.map(function (obj) {
          var display = true;
          var filter = true;
          obj = obj.replace(new RegExp("_X_", "g"), "").replace(new RegExp("_B_", "g"), "").replace(new RegExp("_", "g"), " ");
          display = displayColumns(obj)
          filter = filteredColumns(obj)

          if (obj === "id" && hideId) {
            display = false;
          }

          obj = translateField(obj);
          if (obj !== "POS") {
            return {
              name: obj,
              options: {
                display,
                filter,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    value
                  );
                }
              }
            }
          } else {
            return {
              name: "POS",
              options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (value ? "Si" : ""
                  );
                }
              }
            }
          }
        });
      } catch (error) {
        //console.error(error);
      }

      /*const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
        TablePaginationActions,
      );*/


      //_V_ per visibile o no
      var data = tableData;
      if (data && data.length > 0) {
        if (organize === true) {
          tableHead.push({
            name: "Organizza",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onOrganizeClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <Assignment />
                  </IconButton>
                );
              }
            }
          });
        };
        if (articable === true) {
          tableHead.push({
            name: "Extra",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onArticleClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <Store />
                  </IconButton>
                );
              }
            }
          });
        };
        if (localize === true) {
          tableHead.push({
            name: "Localizza",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onLocalizeClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <LocationOn />
                  </IconButton>
                );
              }
            }
          });
        };
        if (detailable === true) {
          tableHead.push({
            name: "Dettaglio",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onDetailableClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <Subtitles />
                  </IconButton>
                );
              }
            }
          });
        };

        if (imageable === true) {
          tableHead.push({
            name: "Immagine",
            options: {
              filter: false,
              show: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onImageClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <ImageIcon />
                  </IconButton>
                );
              }
            }
          });
        };

        if (editable === true) {
          tableHead.push({
            name: "Modifica",
            options: {
              filter: false,
              show: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onEditClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <EditIcon />
                  </IconButton>
                );
              }
            }
          });
        };
        if (finishable === true) {
          tableHead.push({
            name: "Registra uscita",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onFinishClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <ExitToApp />
                  </IconButton>
                );
              }
            }
          });
        };
        if (deletable === true) {
          tableHead.push({
            name: "Cancella",
            options: {
              filter: false,
              show: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <IconButton onClick={() => onDeletableClick(value, tableMeta, updateValue)} style={{ padding: '0px', width: '32px', height: '32px' }} className={classes.button} aria-label="Delete">
                    <IconDelete />
                  </IconButton>
                );
              }
            }
          });
        };

        if (data && data.length > 0) {
          data = tableData.map(function (obj) {
            var arrayData = [];
            for (var key in obj) {
              arrayData.push(obj[key]);
            }
            if (articable === true)
              arrayData.push("articable");

            if (imageable === true)
              arrayData.push("image");

            if (organize === true)
              arrayData.push("organize");

            if (editable === true)
              arrayData.push("edit");

            if (finishable === true)
              arrayData.push("finish");

            if (localize === true)
              arrayData.push("localize");

            if (detailable === true)
              arrayData.push("detail");

            if (deletable === true)
              arrayData.push("delete");

            return arrayData;
          });
        } else {

        }

        var options = {
          filterType: 'dropdown',
          responsive: 'scroll',
          selectableRows: false,
          setRowProps: this.props.setCustomStylingRow ? (row) => this.props.setCustomStylingRow(row) : null
        };

        if (rowsPerPageOptions !== undefined) {
          options.rowsPerPageOptions = rowsPerPageOptions;
        }
        if (rowsPerPage !== undefined) {
          options.rowsPerPage = rowsPerPage;
        }
      } else {

        tableHead = [];
        tableHead.push({
          name: "Generic",
          options: {
            filter: true,
          }
        });
        data = [""];
      }

      if (options)
        if (noOption) {
          options.filter = false;
          options.search = false;
          options.print = false;
          options.download = false;
          options.viewColumns = false;
        } else {
          options.download = false;
        }

      if (onSearchChange && options) {
        options.onSearchChange = onSearchChange;
      }

      var theme = createMuiTheme({
        overrides: {
          MuiTableCell: {
            root: {
              padding: '0px 0px 0px 10px',
              textAlign: 'center',
              paddingRight: '0px'
            }
          },
          MUIDataTableBodyCell: {
            root: {
              fontWeight: "bold",
              textAlign: 'center'
            }
          },
          MuiTableRow: {
            root: {
              height: '0px',
            }
          },
        },
      });

      return (
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            ref="internalTable"
            title={title}
            data={data}
            columns={tableHead}
            options={options}>
          </MUIDataTable>
        </MuiThemeProvider>
      );

    } else {
      return (
        <div>Tabella vuota</div>
      )
    }
  }

  defaultProps = {
    tableHeaderColor: "gray"
  };

  propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
      "warning",
      "primary",
      "danger",
      "success",
      "info",
      "rose",
      "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.array
  };
}

export default withStyles(tableStyle)(CustomTable);
