import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { translateField, displayField, getInputType } from "utilities/helper";
import Select from 'react-select';
import { getObjects } from "api/api";
import CheckBoxesWeek from "components/CheckBoxes/CheckBoxesWeek";

var moment = require('moment');
const styles = {

};

class CustomModalObject extends React.Component {
    constructor(props) {
        super(props);

        var selectedOptionUser = undefined;
        var selectedOptionCustomer = undefined;
        var selectedOptionCustomer2 = undefined;
        var selectedOptionRoom = undefined;
        var selectedOptionStation = undefined;
        var selectedOptionTruck = undefined;
        var selectedOptionUserType = undefined;
        var selectedOptionExtra = undefined;
        var selectedOptionPos = undefined;
        if ('type' in props.object)
            selectedOptionUserType = [{ value: 'UOMO', label: 'UOMO' }];
        if (props.object.type !== undefined)
            selectedOptionUserType = [{ value: props.object.type, label: props.object.type }]

        if ('extra' in props.object)
            selectedOptionExtra = [{ value: '', label: 'Nessun extra' }];
        if ('pos' in props.object)
            selectedOptionPos = 0;
        if (props.object.pos !== undefined)
            selectedOptionPos = props.object.pos

        /*if ('type' in props.object) {
            props.object.type = 'UOMO';
        }*/
        if ('extra' in props.object) {
            props.object.extra = '';
        }
        this.state = {
            object: props.object,
            customers: undefined,
            users: undefined,
            stations: undefined,
            usertype: [{ value: 'UOMO', label: 'UOMO' }, { value: 'DONNA', label: 'DONNA' }, { value: 'COPPIA', label: 'COPPIA' }],
            pos: [{ value: 1, label: 'Pagamento POS' }, { value: 0, label: 'Non pos' }],
            extra: [{ value: '', label: 'Nessun extra' }, { value: 'Telo grande', label: 'Telo grande' }, { value: 'Telo piccolo', label: 'Telo piccolo' }, { value: 'Hammam', label: 'Hammam' }, { value: 'Gettone solare', label: 'Gettone solare' },],
            loadCustomer: true,
            loadStation: true,
            loadRoom: true,
            loadUser: true,
            loadPos: true,
            loadTruck: true,
            selectedOptionUser: selectedOptionUser,
            selectedOptionCustomer: selectedOptionCustomer,
            selectedOptionCustomer2: selectedOptionCustomer2,
            selectedOptionRoom: selectedOptionRoom,
            selectedOptionStation: selectedOptionStation,
            selectedOptionTruck: selectedOptionTruck,
            selectedOptionUserType: selectedOptionUserType,
            selectedOptionPos: selectedOptionPos,
            selectedOptionExtra: selectedOptionExtra
        }
    }

    componentWillReceiveProps(props) {
        var selectedOptionUser = undefined;
        var selectedOptionCustomer = undefined;
        // eslint-disable-next-line
        var selectedOptionCustomer2 = undefined;
        var selectedOptionRoom = undefined;
        var selectedOptionStation = undefined;
        var selectedOptionTruck = undefined;
        var selectedOptionUserType = undefined;
        var selectedOptionExtra = undefined;
        var selectedOptionPos = undefined;
        if ('type' in props.object)
            selectedOptionUserType = [{ value: props.object.type, label: props.object.type }];
        if ('extra' in props.object)
            selectedOptionExtra = [{ value: '', label: 'Nessun extra' }];
        if ('pos' in props.object)
            selectedOptionPos = 0;

        if (props.object.user_id_X_ !== undefined && this.state.users !== undefined) {
            selectedOptionUser = [{ value: props.object.user_id_X_, label: this.state.users.map((user) => { return user.value === props.object.user_id_X_ ? user.label : null }) }]
        }
        if (props.object.customer_id !== undefined && this.state.customers !== undefined) {
            selectedOptionCustomer = [{ value: props.object.customer_id, label: this.state.customers.map((customer) => { return customer.value === props.object.customer_id ? customer.label : null }) }]
        }
        if (props.object.customer_id_2 !== undefined && this.state.customers !== undefined) {
            selectedOptionCustomer2 = [{ value: props.object.customer_id_2, label: this.state.customers.map((customer) => { return customer.value === props.object.customer_id_2 ? customer.label : null }) }]
        }
        if (props.object.room_id !== undefined && this.state.rooms !== undefined) {
            selectedOptionRoom = [{ value: props.object.room_id, label: this.state.rooms.map((room) => { return room.value === props.object.room_id ? room.label : null }) }]
        }
        if (props.object.station_id_X_ !== undefined && this.state.stations !== undefined) {
            selectedOptionStation = [{ value: props.object.station_id_X_, label: this.state.stations.map((station) => { return station.value === props.object.station_id_X_ ? station.label : null }) }]
        }
        if (props.object.truck_id_X_ !== undefined && this.state.trucks !== undefined) {
            selectedOptionTruck = [{ value: props.object.truck_id_X_, label: this.state.trucks.map((truck) => { return truck.value === props.object.truck_id_X_ ? truck.label : null }) }]
        }
        if (props.object.type !== undefined && this.state.usertype !== undefined) {
            selectedOptionUserType = [{ value: props.object.type, label: props.object.type }]
        }
        if (props.object.extra !== undefined && this.state.extra !== undefined) {
            selectedOptionExtra = [{ value: props.object.extra, label: props.object.extra }]
        }
        if (props.object.pos !== undefined && this.state.pos !== undefined) {
            selectedOptionPos = props.object.pos
        }

        if ('pos' in props.object) {
            props.object.type = 'UOMO';
            props.object.extra = '';
        }

        this.setState({
            object: props.object,
            selectedOptionUser: selectedOptionUser,
            selectedOptionCustomer: selectedOptionCustomer,
            selectedOptionCustomer2: selectedOptionCustomer,
            selectedOptionRoom: selectedOptionRoom,
            selectedOptionStation: selectedOptionStation,
            selectedOptionTruck: selectedOptionTruck,
            selectedOptionUserType: selectedOptionUserType,
            selectedOptionExtra: selectedOptionExtra,
            selectedOptionPos: selectedOptionPos
        })
    }

    textInputChanged = (event, key) => {
        var objectEdited = this.state.object;
        objectEdited[key] = event.target.value;

        if (key === "datecard" && objectEdited["id"] === -1) {
            objectEdited["dateexpirecard"] = moment(event.target.value, "YYYY-MM-DD").add(1, 'year').format("YYYY-MM-DD");

        }

        this.setState({ object: objectEdited });
    }

    validatorField = (key) => {
        if (key.includes("_X_")) {
            if (this.state.object[key] === undefined || this.state.object[key] === null || this.state.object[key] === "")
                return true;
        }
        return false;
    }

    fillStations = (stations) => {
        var selectedOptionStation = undefined;
        if (this.state.object.id !== -1) {
            selectedOptionStation = [{ value: this.state.object.station_id_X_, label: stations.data.map((station) => { return station.value === this.state.object.station_id_X_ ? station.label : null }) }]
        }
        this.setState({
            stations: stations.data,
            loadStation: false,
            selectedOptionStation
        });
    }

    fillStationsError = (error) => {
        alert("Errore ricaricare la modifica");
        this.setState({ loadStation: false });
    }

    fillCustomers = (customers) => {
        var selectedOptionCustomer = undefined;
        if (this.state.object.id !== -1)
            selectedOptionCustomer = [{ value: this.state.object.customer_id, label: customers.data.map((customer) => { return customer.value === this.state.object.customer_id ? customer.label : null }) }];
        this.setState({
            customers: customers.data,
            loadCustomer: false,
            selectedOptionCustomer
        });
    }

    fillRooms = (rooms) => {
        var selectedOptionRoom = undefined;
        if (this.state.object.id !== -1)
            selectedOptionRoom = [{ value: this.state.object.room_id, label: rooms.data.map((room) => { return room.value === this.state.object.room_id ? room.label : null }) }];
        this.setState({
            rooms: rooms.data,
            loadRoom: false,
            selectedOptionRoom
        });
    }

    fillPos = () => {
        var selectedOptionPos = undefined;
        if (this.state.object.id !== -1)
            selectedOptionPos = this.state.object.pos
        this.setState({
            loadPos: false,
            selectedOptionPos
        });
    }

    fillCustomersError = (error) => {
        alert("Errore ricaricare la modifica");
        this.setState({ loadCustomer: false });
    }

    fillRoomsError = (error) => {
        alert("Errore ricaricare la modifica");
        this.setState({ loadRoom: false });
    }

    fillTrucks = (trucks) => {
        var selectedOptionTruck = undefined;
        if (this.state.object.id !== -1)
            selectedOptionTruck = [{ value: this.state.object.truck_id_X_, label: trucks.data.map((truck) => { return truck.value === this.state.object.truck_id_X_ ? truck.label : null }) }];

        this.setState({
            trucks: trucks.data,
            loadTruck: false,
            selectedOptionTruck
        });
    }

    fillTrucksError = (error) => {
        alert("Errore ricaricare la modifica");
        this.setState({ loadTruck: false });
    }

    fillUsers = (users) => {
        var selectedOptionUser = undefined;
        if (this.state.object.id !== -1)
            selectedOptionUser = [{ value: this.state.object.user_id_X_, label: users.data.map((user) => { return user.value === this.state.object.user_id_X_ ? user.label : null }) }];

        this.setState({
            users: users.data,
            loadUser: false,
            selectedOptionUser
        });
    }

    fillUsersError = (error) => {
        alert("Errore ricaricare la modifica");
        this.setState({ loadUser: false });
    }

    checkCustomersDropdown = () => {
        if (this.state.customers === undefined && this.state.loadCustomer)
            getObjects("customers/dropdown", this.fillCustomers, this.fillCustomersError)
        else
            return true;

        return false;
    }

    checkRoomsDropdown = () => {
        if (this.state.rooms === undefined && this.state.loadRoom)
            getObjects("rooms/dropdown", this.fillRooms, this.fillRoomsError)
        else
            return true;

        return false;
    }

    checkStationsDropdown = () => {
        if (this.state.stations === undefined && this.state.loadStation)
            getObjects("stations/dropdown", this.fillStations, this.fillStationsError)
        else
            return true;

        return false;
    }

    checkUsersDropdown = () => {
        if (this.state.users === undefined && this.state.loadUser)
            getObjects("users/dropdown", this.fillUsers, this.fillUsersError)
        else
            return true;

        return false;
    }

    checkTruckDropdown = () => {
        if (this.state.trucks === undefined && this.state.loadTruck)
            getObjects("trucks/dropdown", this.fillTrucks, this.fillTrucksError)
        else
            return true;

        return false;
    }

    handleChangeStation = (selectedOptionStation) => {
        var object = this.state.object;
        object.station_id_X_ = selectedOptionStation.value;

        this.setState({
            object: object,
            selectedOptionStation: [{ value: selectedOptionStation.value, label: selectedOptionStation.label }]
        });
    }

    handleChangeCustomer = (selectedOptionCustomer) => {
        var object = this.state.object;
        object.customer_id = selectedOptionCustomer.value;

        this.setState({
            object: object,
            selectedOptionCustomer: [{ value: selectedOptionCustomer.value, label: this.state.customers.map((customer) => { return customer.value === object.customer_id ? customer.label : null }) }]
        });
    }

    handleChangeRoom = (selectedOptionRoom) => {
        var object = this.state.object;
        object.room_id = selectedOptionRoom.value;

        this.setState({
            object: object,
            selectedOptionRoom: [{ value: selectedOptionRoom.value, label: this.state.rooms.map((room) => { return room.value === object.room_id ? room.label : null }) }]
        });
    }

    handleChangeCustomer2 = (selectedOptionCustomer2) => {
        var object = this.state.object;
        object.customer_id_2 = selectedOptionCustomer2.value;

        this.setState({
            object: object,
            selectedOptionCustomer2: [{ value: selectedOptionCustomer2.value, label: this.state.customers.map((customer) => { return customer.value === object.customer_id_2 ? customer.label : null }) }]
        });
    }

    handleChangeUser = (selectedOptionUser) => {
        var object = this.state.object;
        object.user_id_X_ = selectedOptionUser.value;

        this.setState({
            object: object,
            selectedOptionUser: [{ value: selectedOptionUser.value, label: this.state.users.map((user) => { return user.value === object.user_id_X_ ? user.label : null }) }]
        });
    }

    handleChangeType = (selectedOptionUserType) => {
        var object = this.state.object;
        object.type = selectedOptionUserType.value;
        if (selectedOptionUserType.value === "UOMO")
            object.customer_2 = "";
        if (selectedOptionUserType.value === "DONNA")
            object.customer_1 = "";

        this.setState({
            object: object,
            selectedOptionUserType: [{ value: selectedOptionUserType.value, label: selectedOptionUserType.label }]
        });
    }

    handleChangePos = () => {
        var object = this.state.object;
        object.pos = !object.pos;

        this.setState({
            object: object,
            selectedOptionPos: !this.state.selectedOptionPos
        });
    }

    handleChangeExtra = (selectedOptionExtra) => {
        var object = this.state.object;
        object.extra = selectedOptionExtra.value;

        this.setState({
            object: object,
            selectedOptionExtra: [{ value: selectedOptionExtra.value, label: selectedOptionExtra.label }]
        });
    }

    handleChangeTruck = (selectedOptionTruck) => {
        var object = this.state.object;
        object.truck_id_X_ = selectedOptionTruck.value;

        this.setState({
            object: object,
            selectedOptionTruck: [{ value: selectedOptionTruck.value, label: this.state.trucks.map((truck) => { return truck.value === object.truck_id_X_ ? truck.label : null }) }]
        });
    }

    handleChangeWeekDay = name => event => {
        var object = this.state.object;
        object[name] = event.target.checked;
        this.setState({ object });
    };

    render() {
        const { classes, saveButtonClick, title, subtitle } = this.props;
        var modalRender = (null);

        if (this.state.object !== undefined && this.props.open) {
            var keys = Object.keys(this.state.object);
            modalRender = (
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    style={{ left: '25vw', width: '50vw' }}>
                    <div>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>{title}</h4>
                                    <p className={classes.cardCategoryWhite}>{subtitle}</p>
                                </CardHeader>
                                <CardBody>
                                    <div style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                                        <Grid container>
                                            {
                                                keys.map((key) => {
                                                    if (displayField(key)) {
                                                        var rowWeight = 4;
                                                        if (key === "customer_2" || key === "customer_1")
                                                            rowWeight = 6;
                                                        var inputField = (
                                                            <CustomInput
                                                                key={key + "input"}
                                                                error={this.validatorField(key)}
                                                                onChange={(event) => this.textInputChanged(event, key)}
                                                                value={this.state.object[key]}
                                                                labelText={translateField(key.replace(new RegExp("_X_", "g"), '*').replace(new RegExp("_B_", "g"), "").replace(new RegExp("_", "g"), ' '))}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={getInputType(key)}
                                                            />
                                                        );
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "customer_id") {
                                                            this.checkCustomersDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '52px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <div>Nome di lui</div>
                                                                        <Select
                                                                            onChange={this.handleChangeCustomer}
                                                                            name="form-field-customer"
                                                                            options={this.state.customers}
                                                                            value={this.state.selectedOptionCustomer}

                                                                            placeholder="Nome di lui"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "customer_id_2") {
                                                            this.checkCustomersDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '52px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <div>Nome di lei</div>
                                                                        <Select
                                                                            onChange={this.handleChangeCustomer2}
                                                                            name="form-field-customer"
                                                                            options={this.state.customers}
                                                                            value={this.state.selectedOptionCustomer2}

                                                                            placeholder="Nome di lei"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "room_id") {
                                                            this.checkRoomsDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '52px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <div>Camera</div>
                                                                        <Select
                                                                            onChange={this.handleChangeRoom}
                                                                            name="form-field-customer"
                                                                            options={this.state.rooms}
                                                                            value={this.state.selectedOptionRoom}

                                                                            placeholder="Seleziona una camera"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "station_id") {
                                                            this.checkStationsDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '27px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px'
                                                                    }}>
                                                                        <Select
                                                                            onChange={this.handleChangeStation}
                                                                            name="form-field-station"
                                                                            options={this.state.stations}
                                                                            value={this.state.selectedOptionStation}
                                                                            placeholder="Seleziona una partenza" />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "user_id") {
                                                            this.checkUsersDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '27px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <Select
                                                                            onChange={this.handleChangeUser}
                                                                            name="form-field-user"
                                                                            options={this.state.users}
                                                                            value={this.state.selectedOptionUser}

                                                                            placeholder="Seleziona utente"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "type") {
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '52px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <div>Genere</div>
                                                                        <Select
                                                                            onChange={this.handleChangeType}
                                                                            name="form-field-type"
                                                                            options={this.state.usertype}
                                                                            value={this.state.selectedOptionUserType}
                                                                            placeholder="Seleziona il genere"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "pos") {
                                                            this.state.loadPos && this.fillPos();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingTop: '20px' }}>
                                                                    <div>
                                                                        <label style={{ color: 'black' }}>
                                                                            Pagamento con POS :
                                                                            <input type="checkbox"
                                                                                defaultChecked={this.state.selectedOptionPos}
                                                                                onChange={this.handleChangePos} />

                                                                        </label>

                                                                    </div>

                                                                    {/*<Select
                                                                            onChange={this.handleChangePos}
                                                                            name="form-field-type"
                                                                            options={this.state.pos}
                                                                            value={this.state.selectedOptionPos}
                                                                            placeholder="Seleziona il tipo di pagamento"
                                                                        />*/}
                                                                </div>
                                                            );
                                                            rowWeight = 3;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "extra") {
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '52px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <div>Extra</div>
                                                                        <Select
                                                                            onChange={this.handleChangeExtra}
                                                                            name="form-field-type"
                                                                            options={this.state.extra}
                                                                            value={this.state.selectedOptionExtra}

                                                                            placeholder="Seleziona l'extra"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "truck_id") {
                                                            this.checkTruckDropdown();
                                                            inputField = (
                                                                <div style={{ height: '2vh', paddingBottom: '27px' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: '35px',
                                                                        right: '35px',
                                                                    }}>
                                                                        <Select
                                                                            onChange={this.handleChangeTruck}
                                                                            name="form-field-truck"
                                                                            options={this.state.trucks}
                                                                            value={this.state.selectedOptionTruck}

                                                                            placeholder="Seleziona il mezzo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                            rowWeight = 12;
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "card") {
                                                            var value = this.state.object["id"];
                                                            if (value > 0) {
                                                                value = this.state.object[key];
                                                            } else {
                                                                if (this.props.lastCard) {
                                                                    value = this.props.lastCard.replace(/\D/g, '');
                                                                    value = parseInt(value) + 1;
                                                                    value = "NX" + value.toString().padStart(6, '0');
                                                                    // eslint-disable-next-line
                                                                    this.state.object["card"] = value;
                                                                }
                                                            }

                                                            inputField = (
                                                                <CustomInput
                                                                    key={key + "input"}
                                                                    error={this.validatorField(key)}
                                                                    onChange={(event) => this.textInputChanged(event, key)}
                                                                    value={value}
                                                                    labelText={"Numero tessera"}
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={getInputType(key)}
                                                                />
                                                            );
                                                        }
                                                        if (key.replace(new RegExp("_X_", "g"), '') === "monday") {
                                                            inputField = (
                                                                <CheckBoxesWeek handleChange={this.handleChangeWeekDay} object={this.state.object} />
                                                            )
                                                            rowWeight = 4
                                                        }

                                                        if (key === "customer_1" && this.props.object.type === "DONNA") {
                                                            inputField = null;
                                                        }
                                                        if (key === "customer_2" && (this.props.object.type === "UOMO" || this.props.object.type === undefined)) {
                                                            inputField = null;
                                                        }


                                                        return (
                                                            <GridItem key={key + "grid"} xs={12} sm={12} md={rowWeight}>
                                                                {inputField}
                                                            </GridItem>
                                                        )
                                                    }
                                                    else
                                                        return (null);
                                                })}
                                        </Grid>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button style={{}} onClick={() => saveButtonClick(this.state.object)} variant="contained" color="primary">{this.props.textButton ? this.props.textButton : 'Salva'}</Button>
                                    {
                                        this.props.onCancel &&
                                        <Button style={{}} onClick={() => this.props.onCancel(this.state.object)} variant="contained" color="secondary">Cancella</Button>
                                    }
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </div>
                </Modal>);
        }

        return (modalRender);
    }
}

export default withStyles(styles)(CustomModalObject);