export class UserModel {
    constructor(id, username, name, email, type, password) {
        this.id = id;
        this.type = type;
        this.username_X_ = username;
        this.name = name;
        this.email = email;
        this.password = password;
    }

    static get type() {
        return "users";
    }
}
export class CustomerModel {
    constructor(id, firstname, surname, address, city, country, birthday, card, datecard, dateexpirecard, issuedby) {
        this.id = id;
        this.firstname = firstname;
        this.surname = surname;
        //this.document_type = document_type;
        //this.document_id = document_id;
        this.address = address;
        this.city = city;
        this.country = country;
        //this.province = province;
        //this.cap = cap;
        this.birthday = birthday;
        //this.email = email;
        //this.telephone = telephone;
        this.datecard = datecard;
        this.dateexpirecard = dateexpirecard;
        this.issuedby = issuedby;
        this.card = card;
    }

    static get type() {
        return "customers";
    }
}
export class ProductModel {
    constructor(id, name, description, price) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.price = price;
    }

    static get type() {
        return "products";
    }
}
export class RoomModel {
    constructor(id, name, description) {
        this.id = id;
        this.name = name;
        this.description = description;
    }

    static get type() {
        return "rooms";
    }
}
export class RegistrationModel {
    constructor(id, type, customer_1, customer_2, date_start, date_end, key, room_id, amount, pos) {
        this.id = id;
        this.type = type;
        this.customer_1 = customer_1;
        this.customer_2 = customer_2;
        this.room_id = room_id;
        this.pos = pos;
        this.date_start = date_start;
        this.date_end = date_end;
        this.key = key;
        this.amount = amount;
        this.pos = pos;
    }

    static get type() {
        return "registrations";
    }
}