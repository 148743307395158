import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Person from "@material-ui/icons/Person";
import CustomModalObject from "components/Modals/CustomModalObject.jsx";
import { dashboard } from 'api/api.jsx';
import { filterByField } from 'utilities/helper';
import ConfirmModalv2 from "components/Modals/ConfirmModalv2";
import CardFooter from "components/Card/CardFooter.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Table from "components/Table/Table";
import { RegistrationModel } from 'model/Models.jsx';
import { getObjects, postObjects, putObjects, deleteObjects } from 'api/api';
import BuyerBarModal from "components/Modals/BuyerBarModal";
import ConfirmModal from "components/Modals/ConfirmModal";

const tableSubTitle = "Associati all'interno del locale";
const tableTitle = "Associati";
const modalTitle = "Ingresso NEXUS";
const modalSubTitle = "";
const objectType = RegistrationModel.type;
function getNewModel() {
  return new RegistrationModel(-1, 'UOMO');
}

class DashboardReception extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      dataDashboard: null,
      refresh: true,
      page: 0,
      rowsPerPage: 100,
      openConfirm: false,
      open: false,
      object: getNewModel(),
      filter: '',
      customersFiltered: undefined,
      filterOLD: undefined,
      searchText: undefined,
      first: true
    };
  }

  openNotification = () => {
    this.showNotification("tc");
    this.setState({ textNotification: "L'uscita è già stata registrata" });
  }

  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      6000
    );
  }

  saveObject = (object) => {
    if (object.id === -1) {
      postObjects(object, objectType, this.handleClose, this.handleError);
    } else {
      putObjects(object, objectType, this.handleClose, this.handleError);
    }
  };

  handleError = (error) => {
    alert("Controllare di aver inserito nome e numero di chiave o che il numero di chiave non sia già stata emessa.");
  }

  handleOpen = () => {
    this.setState({
      open: true,
      object: getNewModel()
    });
  };

  onArticleClick = (cell, row, event) => {
    var filtered = filterByField(this.state.customersFiltered, "id", row.rowData[0])[0];
    this.setState({
      openBar: true,
      object: filtered
    });
  }

  onDeletableClick = (cell, row, event) => {
    var filtered = filterByField(this.state.customersFiltered, "id", row.rowData[0])[0];
    this.setState({
      openDelete: true,
      object: filtered
    });
  }

  handleCloseBar = () => {
    this.setState({
      openBar: false,
      refresh: true
    });
  };

  onEditClick = (cell, row, event) => {
    var filtered = filterByField(this.state.customersFiltered, "id", row.rowData[0])[0];
    this.setState({
      open: true,
      object: new RegistrationModel(filtered.id, filtered.type, filtered.customer_1, filtered.customer_2, filtered.date_start, filtered.date_end, filtered.key, filtered.room_id, filtered.amount, filtered.pos),
      textButton: "Salva modifiche"
    });
  };

  handleOpenConfirm = () => {
    this.setState({
      openConfirm: true
    });
  };

  handleClose = () => {
    this.setState({ open: false, refresh: true });
  };

  handleConfirmDelete = () => {
    deleteObjects("registrations/" + this.state.object.id, () => this.setState({ openDelete: false, refresh: true }), this.callbackErrorGetObjects);
  };

  callbackErrorGetObjects = (error) => {
    this.setState({
      refresh: false,
      openConfirm: false,
      open: false
    });
  }

  handleConfirm = async () => {
    await getObjects("registration/" + this.state.object.id + "/outdash", this.callbackDashboard, this.callbackErrorGetObjects);
    this.setState({ openConfirm: false, refresh: true });
  };

  handleCloseConfirm = () => {
    this.setState({ openConfirm: false, openDelete: false, refresh: true });
  };

  callbackDashboard = (dashboard) => {
    var customersFiltered;
    if (this.state.searchText && this.state.searchText.trim() !== "") {
      var customers = dashboard.data.customers_live.filter(x => x.key === this.state.searchText);
      if (customers.length > 0) {
        customersFiltered = customers;
        this.setState({
          dataDashboard: dashboard.data,
          refresh: false,
          openConfirm: false,
          customersFiltered
        });
      }
    } else {
      if (this.state.first) {
        this.setState({
          dataDashboard: dashboard.data,
          refresh: false,
          openConfirm: false,
          first: false
        });
      } else {
        customersFiltered = dashboard.data.customers_live.filter(x => x.type === this.state.filterOLD);
        this.setState({
          dataDashboard: dashboard.data,
          refresh: false,
          openConfirm: false,
          customersFiltered
        });
      }
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  onFinishClick = (cell, row, event) => {

    dashboard((dataDashboard) => {
      var filtered = filterByField(dataDashboard.data.customers_live, "id", row.rowData[0])[0];
      this.setState({
        openConfirm: true,
        object: filtered,
        refreshModal: true
      });
    })
  };

  onSearchChange = (searchText) => {
    if (searchText.trim() !== "") {
      var customers = this.state.dataDashboard.customers_live.filter(x => x.key === searchText && x.date_end === "");
      if (customers.length > 0) {
        this.setState({
          customersFiltered: customers,
          searchText: searchText
        });
      }
    } else {
      this.setState({
        customersFiltered: this.state.dataDashboard.customers_live.filter(x => x.type === this.state.filterOLD),
        searchText: searchText
      });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page: page
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  setCustomStylingRow(row) {
    return {
      style: { backgroundColor: row[9] !== '' ? '#f44336' : 'white' }
    };
  }

  render() {
    const { classes } = this.props;
    if (this.state.refresh) {
      dashboard(this.callbackDashboard);
    }
    if (this.state.redirect) {

    }
    if (this.state.filter && this.state.dataDashboard.customers_live) {
      this.setState({
        customersFiltered: this.state.dataDashboard.customers_live.filter(x => x.type === this.state.filter),
        filterOLD: this.state.filter,
        filter: undefined,
      });
    }

    if (this.state.openConfirm && this.state.object.date_end !== "") {
      this.openNotification();
      // eslint-disable-next-line
      this.state.openConfirm = false;
    }
    if (this.state.openBar && this.state.object.date_end !== "") {
      this.openNotification();
      // eslint-disable-next-line
      this.state.openBar = false;
    }
    if (this.state.openDelete && this.state.object.date_end !== "") {
      this.openNotification();
      // eslint-disable-next-line
      this.state.openDelete = false;
    }
    if (this.state.open && this.state.object.id > -1)
      if (this.state.open && this.state.object.date_end !== "") {
        this.openNotification();
        // eslint-disable-next-line
        this.state.open = false;
      }

    var placeholder = (<div>Caricamento dashboard</div>);

    if (this.state.dataDashboard !== null) {

      placeholder = (
        <div>
          {this.state.textNotification && <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={this.state.textNotification}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
          />}
          <Grid container>
            <GridItem xs={12} sm={6} md={3} onClick={this.handleOpen}>
              <Card style={{ backgroundColor: '#76ff03', height: '200px', width: '200px', alignItems: 'center', display: 'table-cell', verticalAlign: 'middle' }}>
                <div style={{ textAlign: 'center' }}>INGRESSO NEXUS</div>
              </Card>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.setState({ filter: 'UOMO' })} style={{ alignSelf: 'center' }}>
              <Card style={{ marginBottom: '10px', backgroundColor: '#303f9f' }}>
                <CardHeader style={{}}>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#ffffff', fontSize: '16px', fontWeight: 'bold' }}>Tot. singoli: {this.state.dataDashboard.registration_singoli_today}</p>
                </CardHeader>
              </Card>
              <Card style={{ marginTop: '10px', backgroundColor: '#303f9f' }}>
                <CardHeader>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#ffffff', fontSize: '16px', fontWeight: 'bold' }}>Restanti: {this.state.dataDashboard.registration_singoli}</p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.setState({ filter: 'DONNA' })} style={{ alignSelf: 'center' }}>
              <Card style={{ marginBottom: '10px', backgroundColor: '#ec407a' }}>
                <CardHeader>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>Tot. singole: {this.state.dataDashboard.registration_singole_today}</p>
                </CardHeader>
              </Card>
              <Card style={{ marginTop: '10px', backgroundColor: '#ec407a' }}>
                <CardHeader>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>Restanti: {this.state.dataDashboard.registration_singole}</p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.setState({ filter: 'COPPIA' })} style={{ alignSelf: 'center' }}>
              <Card style={{ marginBottom: '10px', backgroundColor: '#fbc02d' }}>
                <CardHeader>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>Tot. coppie: {this.state.dataDashboard.registration_coppie_today}</p>
                </CardHeader>
              </Card>
              <Card style={{ marginTop: '10px', backgroundColor: '#fbc02d' }}>
                <CardHeader>
                  <p className={classes.cardTitle} style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>Restanti: {this.state.dataDashboard.registration_coppie}</p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.props.history.push('/products')}>
              <Card>
                <CardHeader color="green" stats icon>
                  <CardIcon color="green" >
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Globale presenze</p>
                  <h3 className={classes.cardTitle}>{this.state.dataDashboard.customers}</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.props.history.push('/products')}>
              <Card>
                <CardHeader color="orange" stats icon>
                  <CardIcon color="orange">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Presenze attuali</p>
                  <h3 className={classes.cardTitle}>{this.state.dataDashboard.customers_inside}</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={2} onClick={() => this.props.history.push('/products')}>
              <Card>
                <CardHeader color="red" stats icon>
                  <CardIcon color="red">
                    <Person />
                  </CardIcon>
                  <p className={classes.cardCategory}>Camere occupate</p>
                  <h3 className={classes.cardTitle}>{this.state.dataDashboard.room_busy}</h3>
                </CardHeader>
                <CardFooter stats>
                </CardFooter>
              </Card>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem xs={false} sm={false} md={'auto'}></GridItem>
            <GridItem xs={12} sm={12} md={11}>
              <Card>
                <CardHeader color="warning">
                  <h4 className={classes.cardTitleWhite} style={{ color: 'black' }}>{tableTitle}</h4>
                  <p className={classes.cardCategoryWhite} style={{ color: 'black' }}>
                    {tableSubTitle}
                  </p>
                </CardHeader>
                <CardBody>
                  {this.state.customersFiltered
                    ? <Table
                      ref="tableReceptionRefs"
                      deletable={true}
                      onDeletableClick={this.onDeletableClick}
                      pagination={true}
                      page={this.state.page}
                      rowsPerPage={this.state.rowsPerPage}
                      onFinishClick={this.onFinishClick}
                      finishable={true}
                      editable={true}
                      onArticleClick={this.onArticleClick}
                      articable={true}
                      onEditClick={this.onEditClick}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      tableHeaderColor="info"
                      tableData={this.state.customersFiltered}
                      onSearchChange={this.onSearchChange}
                      setCustomStylingRow={this.setCustomStylingRow}
                    />
                    : <div> Nessun associato all'interno del locale</div>
                  }
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={false} sm={false} md={'auto'}></GridItem>
          </Grid>
          {this.state.open && <CustomModalObject onCancel={() => this.setState({ open: false })} title={modalTitle} subtitle={modalSubTitle} saveButtonClick={this.saveObject} textButton={"Accettazione socio"} object={this.state.object} open={this.state.open} handleClose={null} />}
          {this.state.openConfirm && <ConfirmModalv2 message={"Vuoi proseguire con la conferma dell'uscita dal locale di " + this.state.object.customer_1} object={this.state.object} open={this.state.openConfirm} handleClose={this.handleCloseConfirm} handleConfirm={this.handleConfirm} />}
          {this.state.openDelete && <ConfirmModal message={"Vuoi proseguire con la cancellazione della registrazione di " + this.state.object.customer_1} object={this.state.object} open={this.state.openDelete} handleClose={this.handleCloseConfirm} handleConfirm={this.handleConfirmDelete} />}
          {this.state.openBar &&
            <BuyerBarModal
              message={"Aggiungi extra"}
              object={this.state.object}
              open={this.state.openBar}
              handleClose={this.handleCloseBar}
              extra={true}
            />}
        </div>
      );
    }
    return placeholder;
  }
}

DashboardReception.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DashboardReception);
