// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from "components/Card/Card.jsx";

const styles = {

};

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            object: props.object,
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            object: props.object,
        })
    }

    callback = (response) => {
        this.setState({ srcImage: this.state.srcImage + response.data })
    }

    callbackError = (error) => {
        alert(error);
    }

    render() {

        var modalRender = (null);
        if (this.state.object !== null) {
            modalRender = (
                <div style={{ width: '100wv', height: '100wh' }}>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.props.open}
                        onClose={this.props.handleClose}
                        style={{}}>
                        <Card style={{
                            position: 'absolute',
                            marginTop: '0px',
                            marginBottom: '0px',
                            left: '50%',
                            top: '50%',
                            width: '700px',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <CardBody>
                                <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                                    {this.props.message} {this.props.object.customer_id_2_name && this.props.object.customer_id_2_name !== "" && (" e " + this.props.object.customer_id_2_name)}
                                </div>
                            </CardBody>
                            <CardFooter>
                                <Button style={{ width: '45%' }} onClick={this.props.handleClose} variant="contained" color="white">No</Button>
                                <Button style={{ width: '45%' }} onClick={this.props.handleConfirm} variant="contained" color="primary">Si</Button>
                            </CardFooter>
                        </Card>
                    </Modal>
                </div>);
        }

        return (modalRender);
    }
}

export default withStyles(styles)(ConfirmModal);