import Geocode from "react-geocode";

export const GOOGLE_API_KEY = ""
export const token = "token";
export const type = "type";
export const fieldType = {
    'delivery_start': 'time',
    'delivery_end': 'time',
    'time_start_X_': 'time',
    'birthday': 'date',
    'datecard': 'date',
    'dateexpirecard': 'date',
    'date_end': 'datetime',
    'date_start': 'datetime',
};
export const lang = {
    'it': {
        'id': 'ID',
        'name': 'Nome',
        'address': 'Indirizzo',
        'city': 'Città',
        'open days': 'Giorni di ritiro',
        'delivery start': 'Orario inizio consegna',
        'delivery end': 'Orario fine consegna',
        'ext code': 'Codice esterno',
        'license plate': 'Targa',
        'customer id': 'Codice associato',
        'province': 'Provincia',
        'latitude': 'Latitudine',
        'longitude': 'Longitudine',
        'repeat': 'Ricorrenza giorni',
        'date finish': 'Data fine',
        'customer': 'Associato',
        'full address': 'Indirizzo',
        'user': 'Utente',
        'time start': "Entrata",
        'time end': "Uscita",
        'distance': 'Distanza (m)',
        'stores count': 'Negozi assegnati',
        'start station': 'Partenza',
        'monday': 'Lunedì',
        'tuesday': 'Martedì',
        'wednesday': 'Mercoledì',
        'thursday': 'Giovedì',
        'friday': 'Venerdì',
        'saturday': 'Sabato',
        'sunday': 'Domenica',
        'station': 'Sede di partenza',
        'full delivery': 'Orario consegna',
        'tripOrganization': 'Nome gita',
        'delivery start trip': 'Orario inizio consegna',
        'delivery end trip': 'Orario fine consegna',
        'amount': 'Importo contributo socio (€)',
        'reason': 'Motivo',
        'created at': 'Data registrazione',
        'description': 'Descrizione',
        'price': 'Prezzo (€)',
        'country': 'Stato',
        'birthday': 'Data di nascita',
        'firstname': 'Nome',
        'surname': 'Cognome',
        'document type': 'Tipo documento (Identità, Patente)',
        'document id': 'Numero documento',
        'document date': 'Data documento',
        'email': 'Email',
        'telephone': 'Telefono',
        'cap': 'Cap',
        'date start': "Data d'ingresso",
        'date end': 'Data di uscita',
        'key': 'Numero chiave',
        'datecard': 'Giorno di emissione tessera',
        'dateexpirecard': 'Scadenza tessera',
        'card': 'Numero tessera',
        'customer 1': 'UOMO',
        'customer 2': 'DONNA',
        'type': 'Tipo',
        'customer id 2': 'ID Associato 2',
        'room id': 'ID camera',
        'pos': 'POS',
        'extra': 'Extra',
        'customer id 2 name': 'Associato 2',
        'products': 'Prodotti',
        'total': 'Totale',
        'issuedby': 'Emessa da',
        'room': 'Camera',
        'dateexp': 'Scadenza tessera'

    }
};
export const columnToHide = [
    'user id',
    'user',
    'id',
    'latitude',
    'longitude',
    'customer id',
    'type',
    'document type',
    'document id',
    'address',
    'cap',
    'province',
    'country',
    'user id',
    'start station',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'ext code',
    'truck id',
    'station id',
    'datecard',
    'password',
    'delivery start',
    'delivery end',
    'tripOrganization id',
    'station id',
    'telephone',
    'city',
    'customer id 2',
    'room id',
    'extra',
    'products',
    'total',
    'date start',
    'date end',
    'dateexpirecard'
];
export const columnFiltered = [
    'customer',
    'station'
];
export const fieldToHide = [
    'id',
    'staticname',
    'latitude',
    'longitude',
    'full_address',
    'customer',
    'user',
    'stores_count',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'station',
    'full_delivery',
    'date_start',
    'date_end',
    'dateexp'
];

export function setLocalStorage(field, value) {
    return localStorage.setItem(field, value);
}

export function getLocalStorage(field) {
    return localStorage.getItem(field);
}

export function filterByField(items, field, value) {
    return items.filter(item => {
        return item[field] === value
    })
}

function replacer(key, value) {
    if (key.includes("_B_")) return undefined;
    else return value;
}

export function getStringify(object) {
    return JSON.stringify(object, replacer).replace(new RegExp("_X_", "g"), "").replace(new RegExp("_B_", "g"), "");
}

export function translateField(field, language) {
    var fieldLength = field.length;
    field = field.replace(/\*/g, "")
    var word = lang['it'][field];
    return word !== undefined ? fieldLength === field.length ? word : word + "*" : field;
}

export function displayColumns(field) {
    return !(columnToHide.indexOf(field) > -1);
}

export function filteredColumns(field) {
    return (columnFiltered.indexOf(field) > -1);
}

export function displayField(field) {
    return !(fieldToHide.indexOf(field.replace(/\*/g, "").replace(new RegExp("_X_", "g"), "").replace(new RegExp("_B_", "g"), "").toLowerCase()) > -1);
}

export function geocoderFromAddress(address, callback) {
    Geocode.setApiKey(GOOGLE_API_KEY);

    // Get latidude & longitude from address.
    Geocode.fromAddress(address).then(
        response => {
            const { lat, lng } = response.results[0].geometry.location;
            callback(lat, lng);
        },
        error => {
            console.error(error);
            alert("Errore durante la ricerca");
        }
    );
}

export function geocoderFromLatLng(lat, lng) {
    Geocode.setApiKey(GOOGLE_API_KEY);

    // Get address from latidude & longitude.
    Geocode.fromLatLng(lat, lng).then(
        response => {
            const address = response.results[0].formatted_address;
            return address;
        },
        error => {
            console.error(error);
        }
    );
}

export function getInputType(field) {
    if (field === undefined)
        return "text";
    var word = fieldType[field];
    var type = word !== undefined ? word : "text";
    //var dafaultValue = "00:00";
    return {
        type
    }
}

export function choose(choice, choice0, choice1, choice2) {
    switch (choice) {
        case 0:
            return choice0;
        case 1:
            return choice1;
        case 2:
            return choice2;
        default:
            break;
    }
}