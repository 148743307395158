import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
const styles = {

};

class CheckBoxesWeek extends React.Component {
    render() {
        const { classes, object, handleChange } = this.props;

        return (
            <Card style={{marginBottom: '10px'}}>
                <CardBody>
                    <div className={classes.root}>
                        <FormControl>
                            <FormLabel component="legend">Giorni della settimana</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.monday} onChange={handleChange('monday')} value="lunedì" />
                                    }
                                    label="lunedì"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.wednesday} onChange={handleChange('wednesday')} value="mercoledì" />
                                    }
                                    label="mercoledì"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.friday} onChange={handleChange('friday')} value="venerdì" />
                                    }
                                    label="venerdì"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.sunday} onChange={handleChange('sunday')} value="domenica" />
                                    }
                                    label="domenica"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel component="legend"></FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.tuesday} onChange={handleChange('tuesday')} value="martedì" />
                                    }
                                    label="martedì"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.thursday} onChange={handleChange('thursday')} value="giovedì" />
                                    }
                                    label="giovedì"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={object.saturday} onChange={handleChange('saturday')} value="sabato" />
                                    }
                                    label="sabato"
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(CheckBoxesWeek);